<script setup lang="ts">
import { defineProps } from 'vue';

const properties = withDefaults(
	defineProps<{
		icon: string;
		id: string;
		size?: string;
    class?: string;
	}>(),
	{
		size: "medium",
    class: undefined
	},
);

</script>

<template>
    <ods-icon-button :icon="properties.icon" :id="properties.id" :class="properties?.class" :size="properties.size" />
</template>
